import dynamic from 'next/dynamic';
import Wrapper from 'components/wrapper';
import s from 'styles/not-found';
import Button from 'components/button';
import wpApi from 'util/wp-api';
import {SiteOptionsProvider} from 'providers/useOptions';
import PageHead from 'components/page-head';

export async function getStaticProps () {
	const siteOptions = await wpApi.request('site-options');
	return {props: {siteOptions}};
}

export default function NotFound ({siteOptions}) {
	const Page = dynamic(() => import('components/page'));

	return (
		<>
			<PageHead customTitle="Page Not Found" />

			<SiteOptionsProvider value={siteOptions}>
				<Page>
					<Wrapper>
						<s.NotFound>
							<s.Title>Page Not Found</s.Title>
							<s.Message>The page you&apos;re looking for doesn&apos;t exist.</s.Message>
							<div>
								<Button href="/">Go Home</Button>
							</div>
						</s.NotFound>
					</Wrapper>
				</Page>
			</SiteOptionsProvider>
		</>
	);
};